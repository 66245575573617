import { Alert, Body, Box, Link, LoadingButton, LoadingButtonProps, Stack } from '@lanekassen/ui';
import { useState } from 'react';
import purify from 'dompurify';
import { formatDate } from '@lanekassen/utils';
import AvtaleSeksjon from './AvtaleSeksjon';
import Avtale from './Avtale';
import { useForsideteksterContext } from '../context/forsideteksterContext';
import { AggrementsResponse } from '../mockdata/agreements';
import TrekkspillWithLoader from './TrekkspillWithLoader';
import TrekkspillElementWithLoader from './TrekkspillElementWithLoader';
import TilleggsinfoBlokkWithLoader from './TilleggsinfoBlokk';
import { useOpprettSigneringsOppdragMutation } from '../hooks/queries';
import { useCurrentLanguage } from '../hooks/currentLangauge';

const fDate = (date: string | Date) =>
  formatDate(
    date,
    {
      // @ts-ignore @lanekassen/utils tar feil her
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    },
    'no',
  );

interface ForsideAvtalerProps {
  fikkTekniskFeil: boolean;
  foreldresigneringState?: AggrementsResponse;
}
function ForsideAvtaler({ fikkTekniskFeil, foreldresigneringState }: ForsideAvtalerProps) {
  const { localeCode } = useCurrentLanguage();
  const [isLoadingStates, setIsLoadingStates] = useState<Record<string, boolean>>({});
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>();

  const { mutate: opprettSigneringsOppdrag } = useOpprettSigneringsOppdragMutation();
  const forsideTekster = useForsideteksterContext();

  async function gaTilSignering(kryptertSakId: string) {
    setIsLoadingStates((prev) => ({ ...prev, [kryptertSakId]: true }));
    opprettSigneringsOppdrag(
      { sakIdKryptert: kryptertSakId, lang: localeCode },
      {
        onError: () => {
          setErrorMessages((prev) => ({ ...prev, [kryptertSakId]: forsideTekster.meldingTekniskFeilSigner }));
        },
        onSettled: () => setIsLoadingStates((prev) => ({ ...prev, [kryptertSakId]: false })),
        onSuccess: (data) => {
          window.open(data, '_top');
        },
      },
    );
  }

  if (fikkTekniskFeil || !foreldresigneringState)
    return (
      <Alert severity="error" isDense>
        <Body
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(forsideTekster.meldingTekniskFeilSignerteAvtaler.value),
          }}
        />
      </Alert>
    );
  return (
    <Stack spacing={4}>
      {foreldresigneringState.harSignertEldreAvtale && (
        <Alert severity="info" isDense>
          <Body
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(forsideTekster.meldingNyAvtaleTilSignering.value),
            }}
          />
        </Alert>
      )}
      <Box>
        <Body
          isBold
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(forsideTekster!.standardingress.value),
          }}
        />
        <Body
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(forsideTekster!.standardtekst.value),
          }}
        />
        {forsideTekster.tilleggsinfo.map((tillegsInfoId) => (
          <TilleggsinfoBlokkWithLoader contentGuid={tillegsInfoId.contentGuid} key={tillegsInfoId.contentGuid} />
        ))}
      </Box>
      {!!foreldresigneringState.agreementsForSigning.length && (
        <AvtaleSeksjon
          feilmeldingstekst={forsideTekster.meldingTekniskFeilAvtaleTilSignering.value}
          fikkTekniskFeil={fikkTekniskFeil}
          tittel={forsideTekster.tittelTilSignering}
        >
          {foreldresigneringState?.agreementsForSigning.map((agreement) => (
            <Avtale
              key={agreement.cryptedId}
              navn={agreement.name}
              belop={agreement.amount}
              larested={agreement.educationalInstitute}
              opprettet={fDate(agreement.createdDate)}
              statusText={forsideTekster!.usignertStatus}
              color="primary"
              epiTekster={{
                tittelAvtale: forsideTekster!.tittelAvtale,
                belop: forsideTekster!.belop,
                datoOpprettet: forsideTekster!.datoOpprettet,
                larested: forsideTekster!.larested,
                status: forsideTekster!.avtaleStatus,
              }}
            >
              <SignerKnapp
                errorMessage={errorMessages?.[agreement.cryptedId!]}
                isLoading={isLoadingStates[agreement.cryptedId!]}
                variant="solid"
                onClick={() => gaTilSignering(agreement.cryptedId!)}
                role="link"
              >
                {forsideTekster!.signerAvtaleTekst}
              </SignerKnapp>
            </Avtale>
          ))}
        </AvtaleSeksjon>
      )}
      {/* TODO: Denne er hardkodet i gamle foreldresignering, må inn i EPI */}
      {!!foreldresigneringState?.signedAgreements.length && (
        <AvtaleSeksjon
          tittel={forsideTekster.avtaleSeksjonTittel}
          feilmeldingstekst={forsideTekster.meldingTekniskFeilSignerteAvtaler.value}
          fikkTekniskFeil={fikkTekniskFeil}
        >
          {foreldresigneringState?.signedAgreements.map((agreement) => (
            <Avtale
              key={agreement.cryptedId}
              navn={agreement.name}
              belop={agreement.amount}
              larested={agreement.educationalInstitute}
              opprettet={fDate(agreement.createdDate)}
              statusText={`${forsideTekster!.signertLabel} ${fDate(agreement.signedDate)} ${
                forsideTekster!.signertAvLabel
              } ${agreement.signedBy}`}
              color="success"
              epiTekster={{
                tittelAvtale: forsideTekster!.tittelAvtale,
                belop: forsideTekster!.belop,
                datoOpprettet: forsideTekster!.datoOpprettet,
                larested: forsideTekster!.larested,
                status: forsideTekster!.avtaleStatus,
              }}
            >
              <Link
                href={`/api/mt1534/Signering/HentSignertDokument?${new URLSearchParams({
                  arkivreferanseKryptert: agreement.cryptedDocumentArchiveId || '',
                  eksterntArkiv: agreement.eksterntArkiv!.toString(),
                })}`}
              >
                {forsideTekster.lenkeTilSignertAvtale}
              </Link>
            </Avtale>
          ))}
        </AvtaleSeksjon>
      )}
      {/* TODO: Fiks dette når man henter data fra /foreldresignering */}
      {forsideTekster?.trekkspill.map(({ contentGuid }) => (
        <TrekkspillWithLoader contentGuid={contentGuid} key={contentGuid}>
          {(ider: string[]) => (
            <div>
              {ider.map((trekkspillelementGuid) => (
                <TrekkspillElementWithLoader key={trekkspillelementGuid} contentGuid={trekkspillelementGuid} />
              ))}
            </div>
          )}
        </TrekkspillWithLoader>
      ))}
    </Stack>
  );
}

function SignerKnapp({
  errorMessage,
  isLoading,
  onClick,
  ...restProps
}: LoadingButtonProps & { errorMessage: string | undefined }) {
  if (errorMessage)
    return (
      <Alert severity="error" variant="outlined" isDense>
        {errorMessage}
      </Alert>
    );

  return (
    <LoadingButton
      isLoading={isLoading}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      onClick={(e) => onClick?.(e)}
    />
  );
}

export default ForsideAvtaler;
